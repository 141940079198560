@charset "UTF-8";
@import 'variables';

.parentAbs {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: auto;
    bottom: 0;
    height: 60%;
    background-color: $secondary-theme-color-light;

    @media (min-width: 575.98px) {
        top: 700px;
    }

    @media (min-width: 768px) {
        bottom: 300px;
        top: auto;
        background-color:transparent;
        height: auto;
    }

    @media (min-width: 1024px) {
        bottom: 400px;
    }

    @media (min-width: 1200px) {
        bottom: 300px;
    }
}

.form-control {
    &.is-invalid {
        //background-image: url("/bertels/assets/images/invalid_indicator.svg");
        background-repeat: no-repeat;
        background-size: 6%;
        background-position-y: center;
        background-position-x: 98%;
    }
}

.groundBg {
    width: 100%;
    height: auto;
}

.backgroundDiv {
    height: 1300px;
    position: relative;

    @media (min-width: 575.98px) {
        height: 1200px;
        position: relative;
    }

    @media (min-width: 768px) {
        height: 1000px;
    }

    @media (min-width: 1024px) {
        height: 1100px;
    }

    @media (min-width: 1200px) {
        height: 1400px;
    }
}

.backgroundPanel {
    width: 100vw;
    height: 100vh;

    @media (min-width: 575.98px) {
        height: 730px;
    }
}

.bottom-container {
    position: relative;
    z-index: 3;
}

.specific_content-wrapper {
    position: relative;
    z-index: 4;
}

#footer {
    background-color: transparent;
}

.banner {
    position: relative;
    width: 100%;
    display: flex;
}

iframe {
    &.video {
        width: 100vw;
        height: calc(0.49 * 100vw);
        margin: auto;
    }
}

::-webkit-input-placeholder {
    color: $secondary-theme-color !important;
    opacity: 1;
}

::-moz-placeholder {
    color: $secondary-theme-color !important;
    opacity: 1;
}

:-ms-input-placeholder {
    color: $secondary-theme-color !important;
    opacity: 1;
}

:-moz-placeholder {
    color: $secondary-theme-color !important;
    opacity: 1;
}

.captcha-wrapper {
    margin-left: -24px;

    @media (min-width: 370px) {
        margin-left: -19px;
    }

    @media (min-width: 400px) {
        margin-left: 0;
    }
}

.linksContainer {
    a {
        @media (min-width: 768px) {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
}

.formContainerBlock {
    @media (min-width: 1600px) {
        width: 810px;
    }
}

.address-block {
    @media (min-width: 1600px) {
        width: 500px;
    }
}

div.textContentBlock {
    div.text-content {
        padding-bottom: 10rem!important;
    }
}
